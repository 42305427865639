import React from "react";

import { MainSliderComponent } from "./MainSliderComponent";
import { CollectionPage } from "./CollectionPage";
const Landing: React.FC = () => {
  return (
    <>
      {/* <MainSliderComponent></MainSliderComponent> */}
      <CollectionPage></CollectionPage>
    </>
  );
};

export default Landing;
