import React, { FC, useState } from "react";
import { ImageData } from "./CollectionPage";

interface ImageCardProps {
  instagramCode: ImageData;
}

export const ImageCardComponent: React.FC<ImageCardProps> = ({
  instagramCode
}) => {
  const [pending, setPending] = useState<boolean>(true);
  const hideSpinner = () => {
    console.log("hide spinner...");
    setPending(false);
  };

  return (
    <>
      <div className="col">
        <div className="card shadow-sm">
          <div className="card-body">
            {pending && (
              <div
                className="spinner-border text-primary loading-icon"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <iframe
              width="100%"
              height="600"
              src={`https://www.instagram.com/p/${instagramCode.code}/embed`}
              frameBorder={0}
              onLoad={hideSpinner}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
