import React, { FC, useLayoutEffect, useState } from "react";
import { ImageCardComponent } from "./ImageCards";
import gowns from "../images/category/GOWNS.jpeg";
import blowses from "../images/category/blowses.jpeg";
import ethnic from "../images/category/ethnic.jpeg";
import kids from "../images/category/kids.jpeg";
import data from "./data.json";

interface CollectionsProps {}

export interface Category {
  name: string;
  image: string;
}

export interface ImageData {
  code: string;
}

export interface ShukyEntity {
  category: string;
  label: string;
  images: ImageData[];
}

export const CollectionPage: React.FC<CollectionsProps> = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [showCategories, setShowCategories] = useState<boolean>(true);
  const [datalist, setDataList] = useState<ShukyEntity[]>(data);

  const gotoCategory = (category: string) => {
    setShowCategories(false);
    let categoryData = datalist.filter((item) => item.category === category);
    if (categoryData && categoryData.length > 0) {
      setImages(categoryData[0].images);
    }
  };

  return (
    <>
      <div className="album py-5 ">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            {showCategories && (
              <React.Fragment>
                <div className="col">
                  <div
                    className="profile-card-2"
                    onClick={() => gotoCategory("GOWNS")}
                  >
                    <img
                      src={gowns}
                      className="img img-responsive"
                      height={600}
                    />
                    <div className="profile-name">GOWNS</div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="profile-card-2"
                    onClick={() => gotoCategory("BLOWSES")}
                  >
                    <img
                      src={blowses}
                      className="img img-responsive"
                      height={600}
                    />
                    <div className="profile-name">BLOWSES</div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="profile-card-2"
                    onClick={() => gotoCategory("KIDS")}
                  >
                    <img
                      src={kids}
                      className="img img-responsive"
                      height={600}
                    />
                    <div className="profile-name">KIDS</div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="profile-card-2"
                    onClick={() => gotoCategory("ETHNIC_WEARS")}
                  >
                    <img
                      src={ethnic}
                      className="img img-responsive"
                      height={600}
                    />
                    <div className="profile-name">ETHNIC WEARS</div>
                  </div>
                </div>
              </React.Fragment>
            )}

            {!showCategories && (
              <div className="d-grid gap-2">
                <button
                  className="btn"
                  type="button"
                  onClick={() => setShowCategories(true)}
                >
                  Back to Categories
                </button>
              </div>
            )}

            {!showCategories &&
              images &&
              images.map((item, index) => {
                return (
                  <ImageCardComponent
                    key={`imagecars_${item.code}_${index}`}
                    instagramCode={item}
                  ></ImageCardComponent>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
