import React from "react";
import "./Header.css";

const Header: React.FC = () => {
  return (
    <header className="py-4 header">
      <div className="container d-flex flex-wrap justify-content-center">
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none"
        >
          <span className="fs-1">SHUKY</span>
          <span className="tag-line">Elegant The Fashion Key</span>
        </a>

        <div className="social-tags">
          <ul>
            <li>
              <a href="https://wa.me/919008871144/?text=Hi Shuky, <Text-Here>">
                <i className="fab fa-whatsapp" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i className="fab fa-facebook-f" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/shukyofficial?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="small-social-tag">
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex small-social-icons">
            <li className="ms-4">
              <a
                className="text-muted"
                href="https://wa.me/919008871144/?text=Hi Shuky, <Text-Here>"
              >
                <i className="fab fa-whatsapp" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-4">
              <a className="text-muted" href="#">
                <i className="fab fa-facebook-f" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-4">
              <a
                className="text-muted"
                href="https://instagram.com/shukyofficial?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
