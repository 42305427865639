import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";

import Header from "./Header";
import Landing from "./Pages/Landing";

const Layout: React.FC = () => {
  return (
    <Router>
      <Header />
      <main>
        <Container>
          <Switch>
            <Route path="/" component={Landing} />
          </Switch>
        </Container>
      </main>
    </Router>
  );
};

export default Layout;
