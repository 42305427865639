import * as React from "react";
import "./shuky.css";
import "./social.css";
import Layout from "./Layout";
import "bootstrap/dist/css/bootstrap.css"; // Import precompiled Bootstrap css
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.css";

export default function App() {
  return <Layout />;
}
